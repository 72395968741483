import Vue from 'vue'
import Keycloak from 'keycloak-js';
import AppConfig from '../../config.js';

let options = {
    url: AppConfig.KC_Base_URL + '/auth',
    realm: 'rasreeRealm',
    clientId: 'adminAppClient',
}
//onLoad: 'login-required'

let _keycloak = Keycloak(options);

const Plugin = {
    install(Vue) {
      Vue.$keycloak = _keycloak
    }
  }
  
  Plugin.install = Vue => {
    Vue.$keycloak = _keycloak
    Object.defineProperties(Vue.prototype, {
      $keycloak: {
        get() {
          return _keycloak
        }
      }
    })
  }
  
  Vue.use(Plugin)

export default Plugin
<template>
  <div>
    <h1>{{ msg }}</h1>
    <div class="row">
      <div class="column" style="width:20%">
        <div class="row">
          <div class="column" style="width:80%">
            <v-text-field label="search box" v-model="searchElement" hint="add the hexadecimal reference between & #x and ;"></v-text-field>
          </div>
          <div class="column" style="width:20%">
            <v-btn @click="renderSearchedElementFn">Find</v-btn>
          </div>
        </div>
        <div class="row">
          <div id="renderSearchedElement" style="font-size:30px;"></div>
        </div>
        <div class="row">
          <h4>Output of digink is</h4>
          <br>
          <p style="font-size:30px;">{{latestDiginkOutput}}</p>
        </div>
        <div class="row">
          <h4>yes if its the expected output<br> otherwise select No</h4>
          <v-radio-group
            v-model="expectedOutput"
            column
          >
            <v-radio
              label="YES"
              :value="true"
            ></v-radio>
            <v-radio
              label="NO"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
      <div class="column" style="width:70%">
        <div v-if="iinkjsEditorType==='TEXT'" class="writing-container" touch-action="none" ref="editor"></div>
        <div v-else class="writing-container" touch-action="none" ref="mathEditor"></div>
      </div>
      <div class="column" style="width:10%">
        <v-btn @click="clearEditorFn">Clear</v-btn><br><br>
        <!--<v-btn @click="saveContentFn">Submit</v-btn><br><br>-->
        <v-btn @click="submitContentFn">Submit</v-btn><br><br>
        <v-btn @click="downloadDiginkDataFn">Download Latest</v-btn><br><br>
        <v-btn @click="saveContentFn">Save to server</v-btn>
        <!--<v-btn v-if="iinkjsEditorType==='TEXT'" @click="switchToMathFn">Math</v-btn>
        <v-btn v-else @click="switchToTextFn">Text</v-btn>-->
      </div>
    </div>

    <!--<div class="row">
      <div class="column" style="width:80%">
        <v-textarea v-model="restAPIinput" label="Input strokes info"></v-textarea>
      </div>
      <div class="column" style="width:20%">
        <v-btn @click="restAPIRequestFn">REST API</v-btn>
      </div>
    </div>
    
    <hr><br>
    <h4>Output of REST API request is</h4>
    <br>
    <p>{{restAPIoutput}}</p>
    <hr><br><br>
    <h4>Output candidates are</h4>
    <br>
    <p>{{outputInfo}}</p>
    <br>-->
    <h4>Input Info</h4>
    <br>
    <p>{{inputInfo}}</p>
  </div>
</template>

<script>
import axios from 'axios'
import iink from 'iink-js'
import AppConfig from '../../config.js';
  export default {
    name: 'digitalInk',
    data(){
      const toBlob = (data, mimeType) => new Blob([data], {type: mimeType});
      const texttoBlob = (data, mimeType) => {
        return toBlob(data, mimeType);
      };
      let tmpStokeInput = {
          "width": 722,
          "height": 620,
          "contentType": "Math",
          "conversionState": "DIGITAL_EDIT",
          "theme": "ink {color: #000000; -myscript-pen-width: 1; -myscript-pen-fill-style: none; -myscript-pen-fill-color: #FFFFFF00;} mycolor {color: #120f51;} .math { font-family: STIXGeneral;} .math-solved {font-family: STIXGeneral; color: #A8A8A8FF;} .text {font-family: Open Sans; font-size: 10;}",
          "strokeGroups": [
            {
              "strokes": [
                {
                  "id": "string",
                  "x": [
                      179,
                      176,
                      173,
                      170,
                      169,
                      168,
                      168,
                      171,
                      174,
                      177,
                      180,
                      182,
                      182,
                      182,
                      182,
                      179
                  ],
                  "y": [
                      345,
                      345,
                      346,
                      348,
                      351,
                      354,
                      357,
                      359,
                      360,
                      360,
                      359,
                      356,
                      353,
                      350,
                      347,
                      345
                  ],
                  "t": [
                      1671789494373,
                      1671789494635,
                      1671789494782,
                      1671789494909,
                      1671789495006,
                      1671789495097,
                      1671789495313,
                      1671789495488,
                      1671789495561,
                      1671789495679,
                      1671789495818,
                      1671789495963,
                      1671789496103,
                      1671789496227,
                      1671789496398,
                      1671789496576
                  ],
                  "p": [
                      0.5,
                      0.8267949192431123,
                      0.6831899108492184,
                      0.7007103955941203,
                      0.6831899108492184,
                      0.6831899108492184,
                      0.6761535882018319,
                      0.7007103955941203,
                      0.6831899108492184,
                      0.6761535882018319,
                      0.6831899108492184,
                      0.7007103955941203,
                      0.6761535882018319,
                      0.6761535882018319,
                      0.6761535882018319,
                      0.7007103955941203
                  ],
                  "pointerType": "mouse",
                  "pointerId": 0
                }
              ],
              "penStyle": "color: #120f51;↵-myscript-pen-width: 2;",
              "penStyleClasses": "ink mycolor"
            }
          ],
          "configuration": {
            "alwaysConnected": true,
            "lang": "en_US",
            "text": {
              "margin": {
                "top": 10,
                "left": 15,
                "right": 15,
                "bottom": 10
              },
              "guides": {
                "enable": true
              },
              "configuration": {"addLKText": true}
            },
            "export": {
              "image": {
                "guides": true,
                "viewport": {
                  "x": 0,
                  "y": 0,
                  "width": 0,
                  "height": 0
                }
              },
              "jiix": {
                "strokes": true,
                "style": false,
                "bounding-box": false,
                "text": {
                  "chars": false,
                  "words": true
                }
              },
              "image-resolution": 300
            },
            "gesture": {
              "enable": true
            }
          },
          "xDPI": 96,
          "yDPI": 96
        }
      let stringifiedInputStoke = JSON.stringify(tmpStokeInput)
      return{
        msg: 'Vue integration of iinkJS',
        editor: null,
        textEditor: null,
        mathEditor: null,
        outputInfo: [],
        inputInfo: [],
        allOutputsInfo: [],
        searchElement:"",
        chal_api_Base_URL: AppConfig.Challenge_API_Base_URL,
        exportFileType: {
          'text': {
            mimeType:'text/plain',
            transform: texttoBlob,
            name: 'text',
            extension: 'txt'
          },
          'word': {
            mimeType:'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            //transform: base64toBlob,
            name: 'Word (docx)',
            extension: 'docx'
          },
          'html': {
            mimeType: 'text/html',
            name: 'HTML',
            //transform: toBlob,
            extension: 'html'
          },
          'png': {
            mimeType: 'image/png',
            //transform: base64toBlob,
            name: 'PNG',
            extension: 'png'
          },
          'jpeg': {
            mimeType: 'image/jpeg',
            //transform: base64toBlob,
            name: 'JPEG',
            extension: 'jpg'
          }
        },
        restAPIinput: stringifiedInputStoke,
        restAPIoutput: "",
        iinkjsEditorType: "MATH",
        editorsRef: null,
        textEditorsRef: null,
        mathEditorsRef: null,
        latestDiginkOutput: "",
        expectedOutput: false,
        storeEventsData: [], //capture all events
      }
    },
    beforeMount() {
      this.textEditorsRef = this.$refs.editor
      this.mathEditorsRef = this.$refs.mathEditor
      this.editorsRef = this.textEditorsRef
    },
    mounted () {
      this.switchToTextFn()
    },
    destroyed () {
      // don't forget to close websocket when not need it anymore
      this.editor.close()
    },
    methods: {
      stringifyEvent(eventList) {
        let retval = ""
        for(let e=0; e <= eventList.length; e++){
          if(eventList[e] === undefined){
            continue;
          }
          console.log("each event", eventList[e]);
          const obj = {};
          for (let k in eventList[e]) {
            obj[k] = eventList[e][k];
          }
          if(eventList[e]['target'].editor !== undefined){
            obj['target'] = eventList[e]['target'].editor.model.rawStrokes
          }
          retval = retval + JSON.stringify(obj, (k, v) => {
            if (v instanceof Node) return 'Node';
            if (v instanceof Window) return 'Window';
            return v;
          }, ' ');
        }
        return retval
      },
      downloadDiginkDataFn(){
        console.log("download data is", this.storeEventsData);
        /*let strokesInfo = {}
        strokesInfo["eventsListCaptured"] = this.storeEventsData*/
        /*strokesInfo["output_words"] = this.latestDiginkOutput
        //strokesInfo["candidates"] = this.allOutputsInfo
        strokesInfo["input_strokes"] = this.inputInfo*/
        let str_strokesInfo = this.stringifyEvent(this.storeEventsData)
        //console.log("str download", str_strokesInfo);
        const newDate = new Date();
        let currentTime = newDate.getTime();
        //str_strokesInfo = "'" + currentTime + "': " + str_strokesInfo
        let filename = "diginkData-" + currentTime + ".txt"
        const blob = new Blob([str_strokesInfo], {type: 'text/csv'});
        if(window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else{
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;        
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);
        }
      },
      switchToMathFn(){
        this.iinkjsEditorType = "MATH"
        // Math editor
        this.mathEditor = iink.register(this.$refs.mathEditor, {
          recognitionParams: {
            type: 'MATH',
            protocol: 'WEBSOCKET',
            apiVersion: 'V4',
            server: {
              scheme: 'https',
              host: 'webdemoapi.myscript.com',
              applicationKey: 'f1355ec8-c74a-4da9-8d63-691ab05952eb', //'f1355ec8-c74a-4da9-8d63-691ab05952eb',//16f4d892-0b73-433f-88e9-fc13a28e9e45
              hmacKey: '752acf37-5a45-481b-9361-fcb32cd7f6a1', //'752acf37-5a45-481b-9361-fcb32cd7f6a1',//9bba41b3-3078-4986-86c4-e439916949d7
            },
            iink: {
              math: {
                mimeTypes: ['application/x-latex', 'application/vnd.myscript.jiix', 'application/mathml+xml']
              },
              export: {
                jiix: {
                  strokes: true
                }
              }
            }
          }
        })
        this.editor = this.mathEditor
        this.$refs.mathEditor.addEventListener('changed', (event) => {
          console.log("changed event listner", event);
          console.log("editor is", this.editor);//Main thing
          //model.smartguide.tmpwords or wordtochange
          //model.rawstrokes
          let respObj = JSON.parse(event.detail.exports["application/vnd.myscript.jiix"])
          this.outputInfo = respObj.expressions[0].label
          if(this.outputInfo=== undefined){
            this.outputInfo = event.detail.exports["application/x-latex"]
          }
          this.inputInfo = respObj.expressions[0].items
          //document.getElementById("renderSearchedElement").innerHTML = this.outputInfo
          console.log("outputInfo---", this.outputInfo);
        });
      },
      switchToTextFn(){
        this.iinkjsEditorType = "TEXT"
        // Math editor
        this.editor = iink.register(this.$refs.mathEditor, {
          recognitionParams: {
            type: 'TEXT',
            protocol: 'WEBSOCKET',
            apiVersion: 'V4',
            server: {
              scheme: 'https',
              host: 'webdemoapi.myscript.com',
              applicationKey: 'f1355ec8-c74a-4da9-8d63-691ab05952eb', //'f1355ec8-c74a-4da9-8d63-691ab05952eb',//16f4d892-0b73-433f-88e9-fc13a28e9e45
              hmacKey: '752acf37-5a45-481b-9361-fcb32cd7f6a1', //'752acf37-5a45-481b-9361-fcb32cd7f6a1',//9bba41b3-3078-4986-86c4-e439916949d7
            }
          }
        })
        //this.editor = this.mathEditor
        this.$refs.mathEditor.addEventListener('loaded', (event) => {
          console.log("loaded event listner", event);
          this.storeEventsData.push(event)
        });
        this.$refs.mathEditor.addEventListener('changed', (event) => {
          console.log("changed event listner", event);
          this.storeEventsData.push(event)
        });
        this.$refs.mathEditor.addEventListener('converted', (event) => {
          console.log("converted event listner", event);
          this.storeEventsData.push(event)
        });
        this.$refs.mathEditor.addEventListener('idle', (event) => {
          console.log("idle event listner", event);
          this.storeEventsData.push(event)
        });
        this.$refs.mathEditor.addEventListener('error', (event) => {
          console.log("error event listner", event);
          this.storeEventsData.push(event)
        });
        this.$refs.mathEditor.addEventListener('exported', (event) => {
          this.storeEventsData.push(event)
          console.log("event listner", event);
          console.log("editor is", this.editor);//Main thing
          //model.smartguide.tmpwords or wordtochange
          //model.rawstrokes
          let respObj = JSON.parse(event.detail.exports["application/vnd.myscript.jiix"])
          console.log("respobj is", respObj);
          this.allOutputsInfo = respObj//.words[0].candidates
          this.outputInfo = respObj//.words[0].label
          if(this.outputInfo=== undefined){
            this.outputInfo = event.detail.exports["text/plain"]
          }
          this.inputInfo = this.editor.model.rawStrokes
          //document.getElementById("renderSearchedElement").innerHTML = this.outputInfo
          console.log("outputInfo---", this.outputInfo);
          console.log("inputInfo---", this.inputInfo);
        });
      },
      clearEditorFn(){
        this.editor.clear()
        this.latestDiginkOutput = ""
        this.searchElement = ""
        document.getElementById("renderSearchedElement").innerHTML=""
      },
      renderSearchedElementFn(){
        let unicodeStr = Function("return '\\u"+this.searchElement+"';")()
        console.log("unicode rendered is ", unicodeStr);
        document.getElementById("renderSearchedElement").innerHTML = unicodeStr
      },
      submitContentFn(){
        console.log("submit the digink to get output from recogniser");
        this.latestDiginkOutput = this.outputInfo
      },
      async saveContentFn(){
        console.log("save strokes info function");
        let strokesInfo = {}
        strokesInfo["expectedOutput"] = this.expectedOutput
        strokesInfo["output_words"] = this.latestDiginkOutput
        strokesInfo["input_strokes"] = this.inputInfo
        let str_strokesInfo = JSON.stringify(strokesInfo)
        let apireq_inputDict = {"digitalInkStrokesInfo": str_strokesInfo}
        let resp = await axios.post(this.chal_api_Base_URL + "/v/me/saveDigitalInkStrokes", apireq_inputDict)
        console.log("resp from save content", resp);
      },
      async restAPIRequestFn(){
        console.log("submit content funtion");
        let inputStokes = this.restAPIinput
        let resp = await axios.post("https://cloud.myscript.com/api/v4.0/iink/batch",inputStokes,{
          headers:{
            "applicationKey": "16f4d892-0b73-433f-88e9-fc13a28e9e45",
            "Accept": "text/plain, application/json",
            "Content-Type": "application/json"
          }
        })
        console.log("resp is", resp);
        this.restAPIoutput = resp.data
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.writing-container {
  min-height: 600px;
  min-width: 300px;
}

.column {
  float: left;
  /*width: 20%;*/
  padding: 2px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

</style>
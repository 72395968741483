<template>
  <div class="home">
    <diagramDsl msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import AppConfig from '../../config.js';
import diagramDsl from '@/components/diagramDSLTutor.vue'
//import axios from 'axios'

export default {
  name: 'HomeView',
  components: {
    //HelloWorld,
    diagramDsl
  },
  data(){
    return{
        isAuth: false,
        auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
        get_acc_token: "",
        get_id_token: "",
        get_refresh_token: "",
        //login_url: ""
    }
  },
  /*beforeMount(){
      let request_url = this.auth_api_Base_URL + '/v1/me'
      //console.log("Base URL", request_url)
      if(localStorage.getItem('access-token') != null){
          this.get_acc_token = localStorage.getItem('access-token')
          this.get_id_token = localStorage.getItem('id-token')
          this.get_refresh_token = localStorage.getItem('refresh-token')
      }
      axios.get(request_url,{
          headers:{
              Authorization: 'Bearer ' + this.get_acc_token,
              "id-token": this.get_id_token,
              "access-token": this.get_acc_token,
              "refresh-token": this.get_refresh_token,
          }
      }).then(response => {
          console.log(response.data);
          if(response.headers["status_code"] === "200"){
              this.isAuth = true
          }
          else{
              this.isAuth = false
          }
      });
  }*/
}
</script>

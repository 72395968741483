import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import digitalInk from '../components/digital_ink'
import abhyasCanvas from '../components/abhyasCanvas'
import inputStrokesDataCanvas from '../components/inputStrokesDataCanvas'
import rocketChatIframe from '../components/rocketChatIframe'
//import keycloak from '../plugins/KC_auth.vue';
import AppConfig from '../../config.js';
import axios from 'axios';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/myScript',
    name: 'digitalInk',
    component: digitalInk,
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/nativeCanvas',
    name: 'abhyasCanvas',
    component: abhyasCanvas,
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/diginkRender',
    name: 'inputStrokesDataCanvas',
    component: inputStrokesDataCanvas,
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/rcIframe',
    name: 'rocketChatIframe',
    component: rocketChatIframe,
    meta:{
      isAuthNeeded: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: 'is-active',
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthNeeded) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = AppConfig.UI_Base_URL+"/"
    console.log("base url", basePath)
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      let redirect_to = basePath.slice(0, -1) + to.path
      if(redirect_to.includes("login")){
        redirect_to = basePath
      }
      Vue.$keycloak.login({ redirectUri: redirect_to })
    } else{
      // The user was authenticated, and has the app role
      setInterval(() => {
          Vue.$keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
              localStorage.setItem('access-token', Vue.$keycloak.token);
              localStorage.setItem('refresh-token', Vue.$keycloak.refreshToken);
              localStorage.setItem('id-token', Vue.$keycloak.idToken);
              axios.defaults.headers.common['Authorization'] = "Bearer " + Vue.$keycloak.token
              axios.defaults.headers.common['id-token'] = "Bearer " + Vue.$keycloak.idToken
              axios.defaults.headers.common['access-token'] = "Bearer " + Vue.$keycloak.token
              axios.defaults.headers.common['refresh-token'] = "Bearer " + Vue.$keycloak.refreshToken
              //console.log('Token refreshed' + refreshed);
            } else {
              /*console.log('Token not refreshed, valid for '
                + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 100) + ' seconds');*/
            }
          }).catch(() => {
            //console.log('Failed to refresh token');
          });
        }, 60000)
      next();
    }
    console.log("---2--",Vue.$keycloak)
    if(Vue.$keycloak.authenticated){
      localStorage.setItem('access-token', Vue.$keycloak.token);
      localStorage.setItem('refresh-token', Vue.$keycloak.refreshToken);
      localStorage.setItem('id-token', Vue.$keycloak.idToken);
      axios.defaults.headers.common['Authorization'] = "Bearer " + Vue.$keycloak.token
      axios.defaults.headers.common['id-token'] = "Bearer " + Vue.$keycloak.idToken
      axios.defaults.headers.common['access-token'] = "Bearer " + Vue.$keycloak.token
      axios.defaults.headers.common['refresh-token'] = "Bearer " + Vue.$keycloak.refreshToken
  } else {
    // This page did not require authentication
    next()
  }
}
})

export default router

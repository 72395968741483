<template>
    <div>
        <h1 style="text-align:center;">Native Canvas</h1>
        <p>(refresh page to delete cache)</p>
        <div class="row">
            <div class="column" style="width:20%">
            <div class="row">
                <v-switch
                    v-model="myscriptSwitch"
                    hide-details
                    inset
                    :label="`Req Myscript API ? ${myscriptSwitch.toString()}`"
                ></v-switch><br><br>
            </div>
                <div class="row">
                    <v-select
                        label="Select canvas type"
                        v-model="selectedEditorType"
                        :items="['Text', 'Math']"
                    ></v-select>
                </div>
                <div class="row">
                    <div class="column" style="width:80%">
                        <v-text-field label="search box" v-model="searchElement" persistent-hint hint="add the hexadecimal reference after U+"></v-text-field>
                    </div>
                    <div class="column" style="width:20%">
                        <v-btn @click="renderIntendedCharFn">Find</v-btn>
                    </div>
                </div>
                <div class="row">
                    <div id="renderSearchedElement" style="font-size:30px;"></div><br>
                </div>
                <div class="row" style="text-align: left">
                    <h4>Recognised output is</h4>
                    <br>
                    <p style="font-size:30px;">{{renderRecognisedOutputFn()}}</p><br>
                </div>
                <div class="row" style="text-align: left">
                    <h4>Expected Outcome ?</h4><br>
                    {{expectedOutput}}
                </div>
                <div class="row">
                <h4>x and y coodinates are</h4>
                {{x_cor}} : {{y_cor}}
                </div>
            </div>
            <div class="column" style="width:65%">
                <canvas ref="canvasRef"/>
            </div>
            <div class="column" style="width:15%;text-align:right;">
                <v-btn @click="clearEditorFn">Clear</v-btn><br><br>
                <v-btn @click="submitCharFn">Submit</v-btn><br><br>
                <v-btn @click="showUriObjListFn">Show URI obj list</v-btn><br><br>
                <v-btn @click="downloadCapturedDataSetFn">Download</v-btn><br><br>
                <!--<v-btn @click="deleteSeleted">Delete selected path</v-btn><br><br>
                <v-btn @click="toggleDrawingMode">Toggle drawing</v-btn>-->
            </div>
        </div>
        <div class="row" style="text-align:left; padding:20px;">
            <pre id="renderUriObj"></pre>
        </div>
    </div>
</template>
<script>
// https://developer-support.myscript.com/support/discussions/topics/16000031800
// https://developer.myscript.com/support/account/registering-myscript-cloud/?_gl=1*1uuimli*_ga*MTAzMjk1Mjc1OS4xNjcxMzQwOTU0*_ga_Y8713CLEM6*MTY4MTExMjA5My4xNy4xLjE2ODExMTI0MzAuMC4wLjA.
import AppConfig from '../../config.js';
import {fabric} from "fabric"
import 'fabric-history';
import CryptoJS from "crypto-js"

let jsonIinkjsBodyFormat = {
  configuration: {
    text: {
      guides: { enable: true },
      smartGuide: true,
      smartGuideFadeOut: { enable: false, duration: 10000 },
      mimeTypes: ["text/plain", "application/vnd.myscript.jiix"],
      margin: { top: 20, left: 10, right: 10 },
    },
    math: {
      guides: { enable: true },
      smartGuide: true,
      smartGuideFadeOut: { enable: false, duration: 10000 },
      mimeTypes: ['application/x-latex', 'application/vnd.myscript.jiix', 'application/mathml+xml'],
      margin: { top: 20, left: 10, right: 10 },
    },
    lang: "en_US",
  },
  xDPI: 96,
  yDPI: 96,
  contentType: "Text",
  theme:
    "ink {\ncolor: #000000;\n-myscript-pen-width: 1;\n-myscript-pen-fill-style: none;\n-myscript-pen-fill-color: #FFFFFF00;\n}\n.math {\nfont-family: STIXGeneral;\n}\n.math-solved {\nfont-family: STIXGeneral;\ncolor: #A8A8A8FF;\n}\n.text {\nfont-family: Open Sans;\nfont-size: 10;\n}\n",
  strokeGroups: [
    {
      penStyle: null,
      strokes: [],
    },
  ],
  height: 0,
  width: 0,
  conversionState: "DIGITAL_EDIT",
};

//var canvas = null
export default {
    name: 'abhyasCanvas',
    data(){
        return{
            canvas : null,
            applicationKey : "f1355ec8-c74a-4da9-8d63-691ab05952eb",
            hmacKey : "752acf37-5a45-481b-9361-fcb32cd7f6a1",
            searchElement:"",
            chal_api_Base_URL: AppConfig.Challenge_API_Base_URL,
            myscriptRecognisedOutput: "", //recognised output
            expectedOutput: "",
            mouseDownValue: false,
            x_cor: 0,
            y_cor: 0,
            currentTime: 0,
            strokeDiginkInfo: {
                x: [],
                y: [],
                t: [],
                stokeStartTimeStamp: 0,
                stokeEndTimeStamp: 0,
            }, // per stroke
            screenWidth: (screen.width)*0.70 - 55,
            screenHight: (screen.height)*0.85,
            nativeDigitalInk: [],
            storeCharStrokes: [],
            selectedEditorType: "Text", //MYSCRIPT_HINT
            jsonIinkjsBody: jsonIinkjsBodyFormat,
            myscriptUrl: "https://cloud.myscript.com",
            myscriptPath: "/api/v4.0/iink/batch",
            myscriptHeader: {},
            listOfUriObjs: [],
            eventTimeStamp: 0,
            strokeStartTime: 0,
            eventEndTimeStamp: 0,
            strokeEndTime: 0,
            myscriptSwitch: false, // true if req myscript REST API, otherwise false, by default its false
        }
    },
    mounted() {
        this.canvas = new fabric.Canvas(this.$refs.canvasRef, {
            isDrawingMode: true,
            backgroundColor: '#ffffff',
            width: (screen.width)*0.70 - 55, //approx -55, because 85% of div in grid(2 column in row each 85% and 15% resp.) and 24px padding left and right both sides plus border width
            height: (screen.height)*0.85,
        })
        console.log("width and height if canvas is:", this.canvas.get('width'), this.canvas.get('height'));
        this.canvas.freeDrawingBrush.decimate = 0; //ignore pixels far away then the value(distance)
        this.canvas.on('mouse:move', this.getCoordinates) // TODO: How to make it truely event driven. currently it is static event linkage
        this.canvas.on('mouse:down', this.mouseDownEvent)
        this.canvas.on('mouse:up', this.mouseUpEvent)
    },
    methods: {
        storeUriObjectFn(){
            console.log("store URI object");
            this.listOfUriObjs.push(this.createUriObjFn())
            console.log("list of URI object", this.listOfUriObjs);
        },
        async iinkjsRestApiReqFn(){
            this.jsonIinkjsBody.contentType = this.selectedEditorType
            /*for(let idx = 0;idx<this.nativeDigitalInk.length;idx++){
                let batchFormat = {
                    penStyle: null,
                    strokes: this.nativeDigitalInk[idx],
                }
                this.jsonIinkjsBody.strokeGroups.push(batchFormat)
            }*/
            let batchFormat = {
                penStyle: null,
                strokes: this.nativeDigitalInk,
            }
            this.jsonIinkjsBody.strokeGroups.push(batchFormat)
            try{
                let body = JSON.stringify(this.jsonIinkjsBody).replace(/\n/g, "\\n");
                //g is identifier telling to perform the match globally i.e. find all matches rather than stopping after the first match
                let hmac = new CryptoJS.HmacSHA512(body, this.applicationKey + this.hmacKey).toString(
                CryptoJS.Hex
                );
                this.myscriptHeader = {
                    accept: "application/json,application/vnd.myscript.jiix", // text/plain for just text
                    applicationkey: this.applicationKey,
                    "content-type": "application/json",
                    hmac: hmac,
                }
                let response = await fetch( // TODO: Ok for testing, we need to just make a native batch of collection
                    this.myscriptUrl + this.myscriptPath,
                    {
                        headers: this.myscriptHeader,
                        body: body,
                        method: "POST",
                    }
                );
                // TODO: what is the difference between 't' of native canvas and iinkjs canvas - mouse down event start time is 't' for iinkjs , same for us, values might differ a little bit cz of line of execution
                // TODO: how many can we send in a batch, how to maximize recognistion freely
                let res = await response.json(); // response.text() in case of text/plain
                console.log("res => ", res);
                this.myscriptRecognisedOutput = res
                if(this.renderRecognisedOutputFn() === this.formatIntendedCharFn()){
                    this.expectedOutput = "correct"
                }
                else{
                    this.expectedOutput = "wrong"
                }
            }catch{
                this.expectedOutput = "myscript failure"
            }
        },
        renderRecognisedOutputFn(){
            try{
                if(this.selectedEditorType === 'Text'){
                    return this.myscriptRecognisedOutput.label
                }
                if(this.selectedEditorType === 'Math'){
                    return this.myscriptRecognisedOutput.expressions[0].label
                }
                return ""
            }
            catch{
                return ""
            }
        },
        async submitCharFn(){
            console.log("selectedEditorType", this.selectedEditorType);
            console.log("strokes info is", this.storeCharStrokes);
            //this.nativeDigitalInk.push(this.storeCharStrokes)
            this.nativeDigitalInk = this.storeCharStrokes
            this.storeCharStrokes = []
            this.strokeDiginkInfo = {
                x: [],
                y: [],
                t: [],
                stokeStartTimeStamp: 0,
                stokeEndTimeStamp: 0,
            }
            if(this.myscriptSwitch){
                await this.iinkjsRestApiReqFn()
            }
            this.storeUriObjectFn()
        },
        createUriObjFn(){
            let strokesInfo = {} // this.nativeDigitalInk
            strokesInfo["MYSCRIPT_HINT"] = this.selectedEditorType
            strokesInfo["NATIVE_DIGITAL_INK"] = this.nativeDigitalInk
            strokesInfo["MyScript_Service_Call"] = {}
            strokesInfo["MyScript_Service_Call"]["url"] = this.myscriptUrl
            strokesInfo["MyScript_Service_Call"]["path"] = this.myscriptPath
            strokesInfo["MyScript_Service_Call"]["headers"] = this.myscriptPath
            strokesInfo["MyScript_Service_Call"]["method"] = 'POST'
            strokesInfo["MyScript_Service_Call"]["body"] = this.jsonIinkjsBody
            strokesInfo["MyScript_Service_Call"]["resp"] = this.myscriptRecognisedOutput
            strokesInfo["INTENDED_CHAR"] = this.formatIntendedCharFn()
            strokesInfo["expectedOutput"] = this.expectedOutput
            strokesInfo["user_login_name"] = this.$keycloak.tokenParsed.email
            return strokesInfo
        },
        showUriObjListFn(){
            document.getElementById('renderUriObj').textContent = JSON.stringify(this.listOfUriObjs, undefined, 2);
        },
        downloadCapturedDataSetFn(){
            // TODO: pretty print the json before downloading
            // TODO: should be loadable into python json.load
            // TODO: vuejs-app rendered, canvas reder
            let strokesInfo = this.listOfUriObjs
            this.showUriObjListFn()
            let strokeEPOCH = new Date();
            let currentTime = strokeEPOCH.getTime();
            let str_strokesInfo = JSON.stringify(strokesInfo, undefined, 2) // TODO: inspect strokesinfo, can we use it directly
            let filename = "UNIT_RECOG_ITEM-" + currentTime + ".txt"
            let blob = new Blob([str_strokesInfo], {type: 'text/csv'});
            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
            }
            else{
                let elem = window.document.createElement('a');
                elem.href = window.URL.createObjectURL(blob);
                elem.download = filename;        
                document.body.appendChild(elem);
                elem.click();        
                document.body.removeChild(elem);
            }
        },
        clearEditorFn(){
            console.log("clear editor");
            this.canvas.clear();
            this.myscriptRecognisedOutput = ""
            this.searchElement = ""
            this.storeCharStrokes = []
            this.nativeDigitalInk = []
            this.jsonIinkjsBody = {
                configuration: {
                    text: {
                    guides: { enable: true },
                    smartGuide: true,
                    smartGuideFadeOut: { enable: false, duration: 10000 },
                    mimeTypes: ["text/plain", "application/vnd.myscript.jiix"],
                    margin: { top: 20, left: 10, right: 10 },
                    },
                    math: {
                    guides: { enable: true },
                    smartGuide: true,
                    smartGuideFadeOut: { enable: false, duration: 10000 },
                    mimeTypes: ['application/x-latex', 'application/vnd.myscript.jiix', 'application/mathml+xml'],
                    margin: { top: 20, left: 10, right: 10 },
                    },
                    lang: "en_US",
                },
                xDPI: 96,
                yDPI: 96,
                contentType: "Text",
                theme:
                    "ink {\ncolor: #000000;\n-myscript-pen-width: 1;\n-myscript-pen-fill-style: none;\n-myscript-pen-fill-color: #FFFFFF00;\n}\n.math {\nfont-family: STIXGeneral;\n}\n.math-solved {\nfont-family: STIXGeneral;\ncolor: #A8A8A8FF;\n}\n.text {\nfont-family: Open Sans;\nfont-size: 10;\n}\n",
                strokeGroups: [
                    {
                    penStyle: null,
                    strokes: [],
                    },
                ],
                height: 0,
                width: 0,
                conversionState: "DIGITAL_EDIT",
            }
            document.getElementById("renderSearchedElement").innerHTML=""
        },
        formatIntendedCharFn(){
            let unicodeStr = ""
            try{
                unicodeStr = Function("return '\\u"+this.searchElement+"';")()
                console.log("unicode rendered is ", unicodeStr);
            }
            catch{
                unicodeStr = this.searchElement
            }
            return unicodeStr
        },
        renderIntendedCharFn(){
            document.getElementById("renderSearchedElement").innerHTML = this.formatIntendedCharFn()
        },
        getCoordinates(event){ // events should be light weight, loosly coupled but tightly integrated
            // TODO: make the meat of this call non blocking(queue)
            //console.log("event is ", event.e.timeStamp)
            //event.e.changedTouches[0].clientX //touchmove //mousemove
            //console.log("mouse pointer", this.canvas.getPointer(event));
            let mousePointer = {}
            if(this.mouseDownValue && this.canvas.isDrawingMode){
                if(event.e.type === "mousemove"){
                    mousePointer = {x: event.e.clientX, y: event.e.clientY}//this.canvas.getPointer(event.e)
                }
                else if(event.e.type === "touchmove"){
                    mousePointer = {x: event.e.changedTouches[0].clientX, y: event.e.changedTouches[0].clientY}
                }
                this.x_cor = mousePointer.x
                this.y_cor = mousePointer.y
                this.strokeDiginkInfo["x"].push(this.x_cor)
                this.strokeDiginkInfo["y"].push(this.y_cor)
                this.strokeDiginkInfo["t"].push(event.e.timeStamp)
            }
        },
        mouseDownEvent(event){
            let strokeEPOCH = new Date(); // TODO: strokeEPOCH
            this.strokeStartTime = strokeEPOCH.getTime();
            this.eventTimeStamp = event.e.timeStamp
            this.strokeDiginkInfo = {
                x: [],
                y: [],
                t: [],
                stokeStartTimeStamp: this.strokeStartTime,
                stokeEndTimeStamp: 0,
            }
            this.mouseDownValue = true
            console.log("timestamp is", this.strokeStartTime, this.eventTimeStamp);
        },
        mouseUpEvent(event){
            let strokeEPOCH = new Date();
            this.strokeEndTime = strokeEPOCH.getTime();
            this.eventEndTimeStamp = event.e.timeStamp
            this.mouseDownValue = false
            this.strokeDiginkInfo.stokeEndTimeStamp = this.strokeEndTime
            console.log("per stroke x,y,t lists", this.strokeDiginkInfo)
            this.storeCharStrokes.push(this.strokeDiginkInfo)
            this.strokeDiginkInfo = {
                x: [],
                y: [],
                t: [],
                stokeStartTimeStamp: 0,
                stokeEndTimeStamp: 0,
            }
            console.log("timestamp is", this.strokeEndTime, this.eventEndTimeStamp);
        },
    },
}
</script>
<style>
canvas {
    border: 1px solid #5442b9;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.writing-container {
  min-height: 600px;
  min-width: 300px;
}

.column {
  float: left;
  /*width: 20%;*/
  padding: 2px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>
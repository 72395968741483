import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import authentication from "@/plugins/kc_auth.js"

Vue.use(authentication);

Vue.config.productionTip = false

Vue.$keycloak
  .init({ checkLoginIframe: false })
  .then(() => {
  new Vue({
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})

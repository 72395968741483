<template>
  <div>
    <div>
      <h2>Vuejs + rocket.chat Reference app</h2>
      <iframe
        id="rcChannel"
        src="https://qroc.rasree.com/channel/test-room-1/?layout=embedded"
        title="myframe"
        allowfullscreen allow="autofocus"
        style="height:900px;width:100%"
        allow-same-origin
    ></iframe>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
  export default {
    name: 'rocketChatIframe',
    data(){
      return{}
    },
    async beforeMount() {
        //localStorage.setItem("Meteor.loginToken", this.$keycloak.token)
        //localStorage.setItem("access_token", this.$keycloak.token)
        console.log('--key--', this.$keycloak.token);
        //await this.authenticateIFrame()
    },
    mounted() {
      //
    },
    methods: {
      reloadIframeFn(){
        const iframe = document.getElementById('rcChannel');
        iframe.contentWindow.location.reload()
      },
      clickKcLoginFn(){
        const iframe = document.getElementById('rcChannel');
        // Get the contentWindow of the iframe.
        const contentWindow = iframe.contentWindow;
        let kcLoginBtn = contentWindow.document.getElementsByClassName('keycloak');
        //document.evaluate('//*[@id="login-card"]/div[1]/button', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue;
        console.log("kcLoginBtn", kcLoginBtn);
      },
        async authenticateIFrame() {
            //localStorage.setItem("Meteor.loginToken", this.$keycloak.token)
            //document.getElementById('rcChannel').src = `https://m.vayam.dev/channel/test-room-1/?token=${this.$keycloak.token}`
            //document.getElementById('rcChannel').contentWindow.postMessage({
            //    externalCommand: 'call-custom-oauth-login',
            //    service: 'keycloak',
            //    //token: this.$keycloak.token
            //}, '*');
          /*let authResp = await axios.post("https://qroc.rasree.com/api/v1/login",{
            token: this.$keycloak.token,
          })*/

          // get token from server
          let login_data={
            serviceName: "keycloak",
            accessToken: this.$keycloak["token"],
            idToken: this.$keycloak["idToken"],
            expiresIn: this.$keycloak["tokenParsed"]["exp"]
          }
          let authResp = await axios.post("http://localhost:3000/rcToken",login_data)
          //let authResp = await axios.post('https://qroc.rasree.com/api/v1/login', login_data)
          console.log('authresp is', authResp);
          document.getElementById('rcChannel').contentWindow.postMessage({
              event: 'login-with-token',
              loginToken: authResp.data.data.authToken,
          }, 'https://qroc.rasree.com');
          //localStorage.setItem("Meteor.loginToken", 'LKQc0eYYZK6JLXv69CWw_k5OgwOIGfP9SbCzU1E10F3')
          //document.getElementById('rcChannel').contentWindow.postMessage({
          //    event: 'login-with-token',
          //    loginToken: 'LKQc0eYYZK6JLXv69CWw_k5OgwOIGfP9SbCzU1E10F3'//authResp.data.data.authToken
          //}, 'https://qroc.rasree.com');
        }
    },
  }
</script>


<template>
    <div>        
        <div class="row" style="width: 100%; margin-left:5px; margin-top:1%;">
            <div class="column" style="width: 100%; margin-left:5px;">
                <v-text-field v-model="diagramTitle" label="Title of Diagram"></v-text-field>
                <v-textarea
                outlined
                class="row" style="width: 50%;"
                v-model="diagramDslInput"
                label="DiagramDSL Input area"
                >
                </v-textarea>
                <div class="row" style="width: 50%;text-align:center;">
                    <v-btn  @click="previewDiagramDsl">Create Diagram</v-btn>
                    <v-btn  @click="downloadImgFn">Download IMG</v-btn>
                </div>
            </div>
        </div>
        <div >
            <v-card  style="width: 60%;">
                <div style="border:1px solid black; margin-left:5px" id="diagramDsl_preview"> 
                    <svg  height="700px"
                    width="700px"
                    v-html="previewImageSvg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1200 1200"></svg>
                </div>
            </v-card>
        </div>
    </div>
</template>
<script>
import AppConfig from '../../config.js';
import axios from 'axios';

export default {
    name: 'diagramDsl',
    props: {
        msg: String
    },
    data() {
        return {
            diagramDslInput:"",
            previewImageSvg:"",
            UI_baseURL: AppConfig.UI_Base_URL,
            chal_api_Base_URL: AppConfig.Challenge_API_Base_URL,
            diagramTitle:"",
        }
    },
    methods:{
        downloadImgFn(){
            var svgData = this.previewImageSvg
            var svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
            var svgUrl = URL.createObjectURL(svgBlob);
            var downloadLink = document.createElement("a");
            downloadLink.href = svgUrl;
            if(this.diagramTitle===""){
                this.diagramTitle = "diagram"
            }
            downloadLink.download = this.diagramTitle + ".svg";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        async previewDiagramDsl(){
            let apidata={}
            apidata["dsl_script"]=this.diagramDslInput
        try{
            let resp = await axios.post(this.chal_api_Base_URL+"/v/me/createDiagrams",apidata)
            console.log("get resp", resp)
            this.previewImageSvg = resp.data   
        }catch(error){
            console.log("error in resp :", error.response)
            this.showRespMsg = true
            this.respMsg = error.response.headers["message"]
            }
        },
    }
}
    

</script>
<style>

.column {
    float: left;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}
</style>







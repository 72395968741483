<template>
    <div>
        <h1 style="text-align:center;">Draw Digink Canvas</h1>
        <div class="row">
            <div class="column" style="width:30%">
                <div class="row" style="text-align:left;"><br>
                    <v-btn @click="drawDiginkFn">Draw</v-btn>
                    <v-btn @click="clearCanvasFn">Clear Canvas</v-btn>
                    <v-btn @click="clearInputFn">Clear Input</v-btn>
                </div>
                <div class="row">
                    <v-textarea v-model="strokesStrList" label="Enter the list of strokes" hint="format of input will be like: [{x:[],y:[],t:[]},{x:[],y:[],t:[]}...]" persistent-hint></v-textarea>
                </div>
            </div>
            <div class="column" style="width:65%">
                <canvas ref="canvasRef"/>
            </div>
        </div>
    </div>
</template>
<script>
// https://developer-support.myscript.com/support/discussions/topics/16000031800
// https://developer.myscript.com/support/account/registering-myscript-cloud/?_gl=1*1uuimli*_ga*MTAzMjk1Mjc1OS4xNjcxMzQwOTU0*_ga_Y8713CLEM6*MTY4MTExMjA5My4xNy4xLjE2ODExMTI0MzAuMC4wLjA.
import {fabric} from "fabric"
import 'fabric-history';

//var canvas = null
export default {
    name: 'inputStrokesDataCanvas',
    data(){
        return{
            canvas: null,
            xCordList: [],
            yCordList: [],
            tsList: [],
            strokesStrList: "",
            strokesList: [],
        }
    },
    mounted() {
        this.canvas = new fabric.Canvas(this.$refs.canvasRef, {
            isDrawingMode: false,
            backgroundColor: '#ffffff',
            width: (screen.width)*0.70 - 55, //approx -55, because 85% of div in grid(2 column in row each 85% and 15% resp.) and 24px padding left and right both sides plus border width
            height: (screen.height)*0.85,
        })
        console.log("width and height if canvas is:", this.canvas.get('width'), this.canvas.get('height'));
        //this.canvas.freeDrawingBrush.decimate = 0; //ignore pixels far away then the value(distance)
    },
    methods: {
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async drawDiginkFn(){
            console.log("strokes str list is ", this.strokesStrList);
            this.strokesList = JSON.parse(this.strokesStrList)
            console.log("strokes list is ", this.strokesList);
            let startTimestamp = 0
            for(let i=0;i<this.strokesList.length;i++){
                this.xCordList = this.strokesList[i].x
                this.yCordList = this.strokesList[i].y
                this.tsList = this.strokesList[i].t
                if(i===0){
                    startTimestamp = this.tsList[0]
                }
                let prevStrokeEndTs=0
                for(let j = 0;j<this.xCordList.length - 1; j++){
                    //console.log(parseFloat(this.tsList[j]), typeof parseFloat(this.tsList[j]));
                    this.tsList[j] = parseInt(parseFloat(this.tsList[j])-parseFloat(startTimestamp))
                    //console.log("timestamp is", this.tsList[j]);
                    if(j!==0){
                        await this.sleep(this.tsList[j]-this.tsList[j-1])
                    }
                    else if(j===0 && i!==0){
                        await this.sleep(this.tsList[0]-prevStrokeEndTs)
                    }
                    this.canvas.add(new fabric.Line([parseInt(this.xCordList[j]), parseInt(this.yCordList[j]), parseInt(this.xCordList[j+1]), parseInt(this.yCordList[j+1])], {
                        stroke: 'black'
                    }))
                    if(j===this.xCordList.length - 2){
                        prevStrokeEndTs = parseInt(parseFloat(this.tsList[j+1])-parseFloat(startTimestamp))
                    }
                }
            }
        },
        clearCanvasFn(){
            this.canvas.clear();
        },
        clearInputFn(){
            this.xCordList = []
            this.yCordList = []
            this.tsList = []
            this.strokesList = []
            this.strokesStrList = ""
        }
    },
}
</script>
<style>
canvas {
    border: 1px solid #5442b9;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.writing-container {
  min-height: 600px;
  min-width: 300px;
}

.column {
  float: left;
  /*width: 20%;*/
  padding: 2px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>